// src/pages/ResetSuccess.js

import React from 'react';

const ResetSuccess = () => {


  return (
    <div style={styles.container}>
      <h2>Password Reset Successful</h2>
      <p>Your password has been reset successfully. You can now log in with your new password.</p>
    </div>
  );
};

const styles = {
  container: {
    padding: '2rem',
    maxWidth: '500px',
    margin: '50px auto',
    background: '#fff',
    borderRadius: '5px',
    textAlign: 'center',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  },
  button: {
    padding: '0.75rem 1.5rem',
    fontSize: '1rem',
    backgroundColor: '#4CAF50',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '1rem',
  },
};

export default ResetSuccess;
