// TermsOfService.js
import React from 'react';

const TermsOfService = () => {
  return (
    <div style={styles.container}>
      <h2>Terms of Service</h2>
      <p>
        <strong>Effective Date:</strong> January 17, 2025
      </p>
      <p>
        Welcome to RecallSpot! These Terms of Service (“Terms”) govern your use of our website, mobile application, and related services (collectively, the “Services”). By accessing or using our Services, you agree to be bound by these Terms.
      </p>

      <h3>1. Acceptance of Terms</h3>
      <p>
        By using RecallSpot, you affirm that you are at least 18 years old and have the legal capacity to enter into these Terms. If you do not agree with any part of these Terms, you must not use our Services.
      </p>

      <h3>2. Use of Services</h3>
      <p>
        RecallSpot grants you a limited, non-exclusive, non-transferable license to access and use our Services for your personal, non-commercial purposes. You agree to use the Services in compliance with all applicable laws and regulations.
      </p>

      <h3>3. User Accounts</h3>
      <p>
        To access certain features of our Services, you may need to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately if you suspect any unauthorized use of your account.
      </p>

      <h3>4. User Content</h3>
      <p>
        You may have the opportunity to submit, upload, or otherwise provide content to our Services. You retain ownership of any intellectual property rights in your content. However, by submitting content, you grant RecallSpot a worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, and display your content in connection with providing and promoting the Services.
      </p>

      <h3>5. Prohibited Conduct</h3>
      <p>
        You agree not to:
        <ul>
          <li>Use the Services for any unlawful purpose.</li>
          <li>Attempt to gain unauthorized access to our systems or networks.</li>
          <li>Interfere with the proper functioning of the Services.</li>
          <li>Engage in any form of harassment, abuse, or harmful behavior towards other users.</li>
          <li>Upload viruses or other malicious code.</li>
        </ul>
      </p>

      <h3>6. Termination</h3>
      <p>
        RecallSpot reserves the right to suspend or terminate your access to the Services, without prior notice or liability, for any reason, including if you breach these Terms.
      </p>

      <h3>7. Disclaimer of Warranties</h3>
      <p>
        The Services are provided on an “AS IS” and “AS AVAILABLE” basis. RecallSpot disclaims all warranties of any kind, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.
      </p>

      <h3>8. Limitation of Liability</h3>
      <p>
        To the fullest extent permitted by law, RecallSpot shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, arising from your use of the Services.
      </p>

      <h3>9. Indemnification</h3>
      <p>
        You agree to indemnify, defend, and hold harmless RecallSpot, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or in any way connected with your access to or use of the Services.
      </p>

      <h3>10. Governing Law</h3>
      <p>
        These Terms shall be governed and construed in accordance with the laws of the jurisdiction in which RecallSpot operates, without regard to its conflict of law provisions.
      </p>

      <h3>11. Changes to Terms</h3>
      <p>
        RecallSpot reserves the right to modify these Terms at any time. We will notify you of any changes by updating the “Effective Date” and posting the updated Terms on our website and app. Your continued use of the Services after such changes constitutes your acceptance of the new Terms.
      </p>

      <h3>12. Contact Us</h3>
      <p>
        If you have any questions about these Terms, please contact us at :
        <a href="mailto:support@recallspot.com">support@recallspot.com</a>.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '2rem',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  },
};

export default TermsOfService;
