// src/components/Navbar.js

import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navRef = useRef();

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };

  const closeMobileMenu = () => {
    setIsMobile(false);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'hidden'; // Prevent background scrolling
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'auto'; // Restore scrolling
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'auto';
    };
  }, [isMobile]);

  return (
    <nav className="navbar" ref={navRef}>
      <h1 className="navbar-logo">RecallSpot</h1>
      <button
        className="menu-icon"
        onClick={handleToggle}
        aria-label={isMobile ? 'Close Menu' : 'Open Menu'}
        aria-expanded={isMobile}
      >
        {isMobile ? <FaTimes /> : <FaBars />}
      </button>
      <ul className={isMobile ? 'nav-links mobile' : 'nav-links'}>
        <li>
          <NavLink to="/" end className={({ isActive }) => (isActive ? 'active-link' : '')} onClick={closeMobileMenu}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/disclaimer" className={({ isActive }) => (isActive ? 'active-link' : '')} onClick={closeMobileMenu}>
            Disclaimer
          </NavLink>
        </li>
        <li>
          <NavLink to="/datapolicy" className={({ isActive }) => (isActive ? 'active-link' : '')} onClick={closeMobileMenu}>
            Data Policy
          </NavLink>
        </li>
        <li>
          <NavLink to="/privacypolicy" className={({ isActive }) => (isActive ? 'active-link' : '')} onClick={closeMobileMenu}>
            Privacy Policy
          </NavLink>
        </li>
        <li>
          <NavLink to="/terms-of-service" className={({ isActive }) => (isActive ? 'active-link' : '')} onClick={closeMobileMenu}>
            Terms of Service
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" className={({ isActive }) => (isActive ? 'active-link' : '')} onClick={closeMobileMenu}>
            Contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
