// Disclaimer.js
import React from 'react';

const Disclaimer = () => {
  return (
    <div style={styles.container}>
      <h2>Disclaimer</h2>
      <p>
        <strong>Effective Date:</strong> January 17, 2025
      </p>
      <p>
        The information provided by RecallSpot (“we,” “our,” or “us”) on our website and mobile application is for general informational purposes only. All information is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site or app.
      </p>

      <h3>1. No Professional Advice</h3>
      <p>
        The content provided through RecallSpot is not intended to substitute for professional advice, including but not limited to legal, medical, financial, or technical advice. Always seek the guidance of qualified professionals for any specific concerns or issues related to recalls or other matters.
      </p>

      <h3>2. No Liability for Errors</h3>
      <p>
        While we strive to ensure the accuracy of the information presented, RecallSpot does not guarantee that the data provided is free of errors or omissions. We are not responsible for any loss or damage resulting from reliance on the information contained on our platform. As a tool to assist users, RecallSpot may occasionally miss recalls or present incorrect information. Users are strongly encouraged to verify details through official sources such as FDA websites for complete and accurate information.
      </p>

      <h3>3. External Links Disclaimer</h3>
      <p>
        The platform may include links to external websites or third-party content for additional information or convenience. These links do not constitute an endorsement, sponsorship, or approval by RecallSpot of any third-party website, services, or content. We are not responsible for the availability, accuracy, or reliability of any external content.
      </p>

      <h3>4. Third-Party Data Sources</h3>
      <p>
        RecallSpot aggregates recall information from publicly available sources, including but not limited to the FDA, NHTSA, USDA, and other organizations. While we make every effort to ensure timely updates, we do not guarantee real-time accuracy or comprehensiveness. Users are advised to verify details through the original source when necessary.
      </p>

      <h3>5. Use at Your Own Risk</h3>
      <p>
        Your use of the RecallSpot website, mobile application, and services is solely at your own risk. RecallSpot disclaims all liability for any adverse effects or damages resulting from the use of or inability to use the platform.
      </p>

      <h3>6. No Guarantees</h3>
      <p>
        RecallSpot does not guarantee that all relevant recalls will be included in the information provided. Recall notifications are based on user-provided information and data availability. Users are encouraged to regularly review official recall notifications and updates independently through trusted sources like the FDA.
      </p>

      <h3>7. Changes to This Disclaimer</h3>
      <p>
        We reserve the right to modify this Disclaimer at any time. Updates will be posted on this page with the "Effective Date" updated accordingly. Continued use of our platform constitutes your acceptance of any changes to this Disclaimer.
      </p>

      <h3>8. Contact Us</h3>
      <p>
        If you have any questions or concerns about this Disclaimer, please contact us at:
        <a href="mailto:support@recallspot.com">support@recallspot.com</a>.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '2rem',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  },
};

export default Disclaimer;
