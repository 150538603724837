// src/utils/api.js

import axios from 'axios';

// Create an Axios instance with base configuration
const apiClient = axios.create({
  baseURL: 'https://api.recallspot.com/api', // Update this to your backend's base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to handle forgot password requests
export const postForgotPassword = async (data) => {
  const response = await apiClient.post('/forgot-password', data);
  return response.data;
};

// Function to handle password reset submissions
export const postResetPassword = async (data) => {
  const response = await apiClient.post('/reset-password', data);
  return response.data;
};

// Add the submitContactForm function for the contact endpoint
export const submitContactForm = async (data) => {
  const response = await apiClient.post('/contact', data);
  return response.data;
};

// ... other API functions

export default apiClient;
