// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Disclaimer from './pages/Disclaimer';
import DataPolicy from './pages/DataPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ResetPassword from './pages/ResetPassword';
import ResetSuccess from './pages/ResetSuccess'; // Optional
import ResetFailure from './pages/ResetFailure'; // Optional
import TermsOfService from './pages/TermsOfService';
import Contact from './pages/Contact'; // Import the Contact component

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/datapolicy" element={<DataPolicy />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/reset-success" element={<ResetSuccess />} />  {/* Optional */}
            <Route path="/reset-failure" element={<ResetFailure />} />  {/* Optional */}
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/contact" element={<Contact />} />  {/* New Contact route */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
