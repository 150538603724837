// src/pages/Home.js

import React from 'react';
import './Home.css'; // Import the CSS file

const Home = () => {
  return (
    <div className="home-container">
      <h2 className="home-header">Welcome to RecallSpot</h2>
      <p className="home-text">
        We want to gather all drug, food, vehicle, product, pet, and poultry recall data in one easy-to-access place for everyone to review. At RecallSpot, our mission is to ensure that people stay informed about recalls, understand their frequency, and take the necessary steps to stay safe.
      </p>
      <p className="home-text">
        We utilize direct sources from government agencies to display this data in a clean, easy-to-read, and understandable format. Whether it’s a critical drug recall or a product safety notice, RecallSpot is here to keep you updated and secure.
      </p>
      <p className="home-text">
        Download our iOS app, RecallSpot, to receive instant notifications about recalls that matter to you!
      </p>
      <div className="app-download">
        <a href="https://apps.apple.com/us/app/recallspot/id6741032452" target="_blank" rel="noopener noreferrer">
          <img 
            src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" 
            alt="Download on the App Store" 
          />
        </a>
      </div>
    </div>
  );
};

export default Home;
