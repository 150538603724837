// src/pages/ResetFailure.js

import React from 'react';

const ResetFailure = () => {

  return (
    <div style={styles.container}>
      <h2>Password Reset Failed</h2>
      <p>There was an issue resetting your password. Please try again.</p>
    </div>
  );
};

const styles = {
  container: {
    padding: '2rem',
    maxWidth: '500px',
    margin: '50px auto',
    background: '#fff',
    borderRadius: '5px',
    textAlign: 'center',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  },
  button: {
    padding: '0.75rem 1.5rem',
    fontSize: '1rem',
    backgroundColor: '#d9534f',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '1rem',
  },
};

export default ResetFailure;
