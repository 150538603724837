// DataPolicy.js
import React from 'react';

const DataPolicy = () => {
  return (
    <div style={styles.container}>
      <h2>Data Policy</h2>
      <p>
        <strong>Effective Date:</strong> January 17, 2025
      </p>
      <p>
        At RecallSpot, we are dedicated to protecting your data and ensuring transparency about how we collect, use, and safeguard the information you provide. This Data Policy outlines our practices and your rights regarding your personal information.
      </p>

      <h3>1. Data Collection</h3>
      <p>
        We collect the following types of data to enhance your experience and provide recall notifications:
        <ul>
          <li>Personal Information: Name &amp; email address.</li>
          <li>Location Information: State &amp; city of residence.</li>
          <li>Vehicle Information: Make, model, and year of your vehicle.</li>
          <li>Usage Data: Information about how you interact with our application.</li>
        </ul>
        This data is collected when you register, update your profile, or interact with our services.
      </p>

      <h3>2. Data Usage</h3>
      <p>
        The data we collect is used to:
        <ul>
          <li>Send personalized recall notifications.</li>
          <li>Improve and maintain our platform.</li>
          <li>Analyze usage trends to enhance user experience.</li>
          <li>Provide customer support and respond to inquiries.</li>
          <li>Send targeted marketing communications (with your consent).</li>
        </ul>
        We do not sell or share your data with third parties for marketing purposes without your explicit consent.
      </p>

      <h3>3. Data Protection</h3>
      <p>
        We implement industry-standard security measures, including encryption and secure access controls, to protect your data against unauthorized access, alteration, or disclosure. While we strive to safeguard your information, no method of electronic storage or transmission is completely secure. Therefore, we cannot guarantee absolute security.
      </p>

      <h3>4. Data Sharing</h3>
      <p>
        Your data may be shared with trusted third-party service providers to:
        <ul>
          <li>Send email or SMS notifications.</li>
          <li>Provide technical support and operational services.</li>
          <li>Process payments (if applicable).</li>
          <li>Conduct data analysis and improve our services.</li>
        </ul>
        These providers are contractually obligated to protect your data and use it solely for the purposes outlined above.
      </p>

      <h3>5. User Rights</h3>
      <p>
        You have the following rights concerning your data:
        <ul>
          <li><strong>Access:</strong> Request a copy of the data we hold about you.</li>
          <li><strong>Correction:</strong> Update or correct inaccurate data.</li>
          <li><strong>Deletion:</strong> Request the deletion of your data, subject to legal and operational requirements.</li>
          <li><strong>Data Portability:</strong> Request the transfer of your data to another service.</li>
          <li><strong>Objection:</strong> Object to certain data processing activities.</li>
        </ul>
        To exercise these rights, please contact us at <a href="mailto:support@recallspot.com">support@recallspot.com</a>.
      </p>

      <h3>6. Data Retention</h3>
      <p>
        We retain your data only as long as necessary to fulfill the purposes outlined in this policy or as required by law. When no longer needed, your data will be securely deleted or anonymized.
      </p>

      <h3>7. Children's Privacy</h3>
      <p>
        Our services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have inadvertently received personal information from a child, we will delete such information promptly.
      </p>

      <h3>8. Changes to This Data Policy</h3>
      <p>
        We may update this Data Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. Updates will be posted on this page, and the "Effective Date" will be updated accordingly.
      </p>

      <h3>9. Contact Us</h3>
      <p>
        For questions or concerns about this Data Policy, please contact us at:
        <a href="mailto:support@recallspot.com">support@recallspot.com</a>.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '2rem',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  },
};

export default DataPolicy;
