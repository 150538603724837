import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <h2>Privacy Policy</h2>
      <p>
        <strong>Effective Date:</strong> January 17, 2025
      </p>
      <p>
        At RecallSpot, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website, use our services, and interact with our mobile application.
      </p>
      <h3>1. Information We Collect</h3>
      <p>
        We collect the following information to provide personalized recall notifications and improve your user experience:
        <ul>
          <li>Personal information: Name & email address.</li>
          <li>Location data: State of residence.</li>
          <li>Optional information: Vehicle make, model, and year.</li>
        </ul>
      </p>
      <h3>2. How We Use Your Information</h3>
      <p>
        The information we collect is used for the following purposes:
        <ul>
          <li>Sending recall notifications based on your preferences, location, and optionally provided vehicle information.</li>
          <li>Improving our website, mobile application, and related services.</li>
          <li>Responding to user inquiries and providing customer support.</li>
          <li>Ensuring compliance with applicable laws and regulations.</li>
        </ul>
      </p>
      <h3>3. Data Security</h3>
      <p>
        We implement industry-standard security measures to protect your personal information. These measures include data encryption, secure access controls, and regular security assessments to prevent unauthorized access, alteration, disclosure, or destruction of your data.
      </p>
      <h3>4. Sharing Your Information</h3>
      <p>
        We do not sell your personal information to third parties. However, we may share your information with trusted third-party service providers to:
        <ul>
          <li>Deliver notifications and communications.</li>
          <li>Analyze and improve our services.</li>
        </ul>
        These providers are bound by confidentiality agreements and are only permitted to use your information for the purposes outlined above.
      </p>
      <h3>5. User Rights</h3>
      <p>
        You have the following rights regarding your personal information:
        <ul>
          <li>Access: Request access to the data we have collected about you.</li>
          <li>Correction: Update or correct inaccurate or incomplete information.</li>
          <li>Deletion: Request deletion of your personal information, subject to legal and operational obligations.</li>
        </ul>
        You can manage your preferences and rights through your account settings or by contacting us directly.
      </p>
      <h3>6. Cookies and Tracking</h3>
      <p>
        Our website and app may use cookies and similar tracking technologies to enhance user experience, monitor usage, and deliver relevant notifications. You can manage cookie preferences through your browser settings.
      </p>
      <h3>7. Changes to This Privacy Policy</h3>
      <p>
        We reserve the right to update this Privacy Policy to reflect changes in our practices, services, or legal requirements. Changes will be posted on this page, and the "Effective Date" will be updated accordingly. We encourage you to review this policy periodically.
      </p>
      <h3>8. Contact Us</h3>
      <p>
        If you have questions, concerns, or need assistance regarding this Privacy Policy, please contact us at:
        <a href="mailto:support@recallspot.com">support@recallspot.com</a>.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '2rem',
    lineHeight: '1.6',
  },
};

export default PrivacyPolicy;
