// src/pages/Contact.js

import React, { useState } from 'react';
import { submitContactForm } from '../utils/api';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    nickname: '', // Honeypot field
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Validate the form data using a stricter email regex
  const validateForm = () => {
    const { name, email, message, nickname } = formData;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    if (nickname) {
      alert('Spam Detected: Your submission was flagged as spam.');
      return false;
    }
    if (!name.trim()) {
      alert('Please enter your name.');
      return false;
    }
    if (!email.trim()) {
      alert('Please enter your email.');
      return false;
    }
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address.');
      return false;
    }
    if (!message.trim()) {
      alert('Please enter your message.');
      return false;
    }
    if (message.length > 1000) {
      alert('Message exceeds the 1000 character limit.');
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const response = await submitContactForm(formData);
      alert(response.message);
      setFormData({ name: '', email: '', message: '', nickname: '' });
    } catch (error) {
      alert(error.detail || 'An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-container">
      <h2>Contact Us &amp; FAQ</h2>
      <div className="contact-content">
        {/* Contact Form Section */}
        <div className="contact-form-section">
          <h3>Send Us a Message</h3>
          <form className="contact-form" onSubmit={handleSubmit} noValidate>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              disabled={isSubmitting}
              maxLength="100"
            />

            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="you@example.com"
              value={formData.email}
              onChange={handleChange}
              disabled={isSubmitting}
            />

            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              placeholder="Your message..."
              value={formData.message}
              onChange={handleChange}
              disabled={isSubmitting}
              maxLength="1000"
            ></textarea>

            {/* Honeypot Field */}
            <input
              type="text"
              name="nickname"
              value={formData.nickname}
              onChange={handleChange}
              style={{ display: 'none' }}
              autoComplete="off"
            />

            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </button>
          </form>
          <div className="support-info">
            <p>
              For additional support, please email us at{' '}
              <a href="mailto:support@recallspot.com">support@recallspot.com</a>.
            </p>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="faq-section">
          <h3>Frequently Asked Questions</h3>
          <div className="faq-item">
            <h4>Not getting notifications</h4>
            <ul>
              <li>Log out and then log back in.</li>
              <li>
                If issues persist, try deleting your account and then recreating it using the same email.
              </li>
              <li>Ensure notifications are enabled in your device settings.</li>
            </ul>
          </div>
          <div className="faq-item">
            <h4>Not seeing any recalls</h4>
            <ul>
              <li>Ensure your internet connection is active.</li>
              <li>
                Recall data is provided directly from our backend servers; please check your connection.
              </li>
            </ul>
          </div>
          <div className="faq-item">
            <h4>Can't connect</h4>
            <ul>
              <li>
                We only allow traffic from inside the USA and Canada. Users in Europe or on a VPN may be blocked.
              </li>
              <li>
                Please verify your connection and try again from an allowed region.
              </li>
            </ul>
          </div>
          {/* Additional FAQ items can be added here */}
        </div>
      </div>
    </div>
  );
};

export default Contact;
